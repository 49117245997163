<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="类型" prop="recordtype" style="width: 25%">
              <a-select v-model="queryParams.recordtype">
                <a-select-option v-for="(item, index) in recordTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="查询记录时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="width: 20%;float:right;">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="是否发单" prop="issendorder" style="width: 25%">
              <a-select v-model="queryParams.issendorder">
                <a-select-option value="2">是</a-select-option>
                <a-select-option value="1">否</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="完成标志" prop="recordstatus" style="width: 25%">
              <a-select v-model="queryParams.recordstatus">
                <a-select-option :value="1">处理中</a-select-option>
                <a-select-option :value="2">待验证</a-select-option>
                <a-select-option :value="3">已完成</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="品质改进单号" prop="improveordernum" style="width: 25%">
              <a-input v-model="queryParams.improveordernum"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :scroll="{ x: 1500}" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="recordtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="orderstime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="confirmtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="recordtype" slot-scope="value">
            <span>{{value?recordTypes[value-1].dicvalue:''}}</span>
          </span>
          <span slot="issendorder" slot-scope="value">
            <span>{{value==2?'是':'否'}}</span>
          </span>
          <span slot="recordstatus" slot-scope="value">
            <a-tag :color="value==3?'green':value==2?'yellow':'red'">{{value?processTypes[value-1].value:''}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.superviseid">详情</a-menu-item>
                <a-menu-item v-if="userInfo.usertype=='0'||userInfo.rolename=='督导管理员'||userInfo.rolename=='督导管理员（工程管理）'" :key="'edit-'+record.superviseid">跟进</a-menu-item>
                <a-menu-item v-if="userInfo.usertype=='0'||userInfo.rolename=='督导管理员'||userInfo.rolename=='督导管理员（工程管理）'" :key="'delete-'+record.superviseid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import pagination from '@/mixins/pagination'
import {getCache, getItemFromArrayByKey, getTimeRange} from 'U'
import addOrEditModal from './addOrEditModal'
import {mapState} from "vuex";
import deptselect from "@/mixins/deptselect";
import {deleteSupervise, getSuperviseListByCondition} from "A/patrol";
import { processTypes} from "@/json/wlwhistory";
import moment from "moment";
export default {
  name:'superviseRecording',
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      showAdvanced: false,
      userdepidCascaderSelected: [],
      recordTypes:[],
      processTypes,
      queryInstallDate:null,
      queryParams: {
        monitorpointname:'',
        recordnum:'',
        recordtime:'',
        recordtype:'',
        description:'',
        measure:'',
        recordman:'',
        receiver:'',
        result:'',
        finishsign:'',
        confirmman:'',
        recordstatus:'',
        issendorder:'',
      },
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          width: 180
        },
        {
          title: '记录编号',
          dataIndex: 'recordnum',
          key: 'eventnum',
          ellipsis: true,
          width:100
        },
        {
          title: '记录时间',
          dataIndex: 'recordtime',
          key: 'recordtime',
          ellipsis: true,
          scopedSlots: { customRender: 'recordtime' },
          width: 150
        },
        {
          title: '接单时间',
          dataIndex: 'orderstime',
          key: 'orderstime',
          ellipsis: true,
          scopedSlots: { customRender: 'orderstime' },
          width: 150
        },
        {
          title: '督导人员确认时间',
          dataIndex: 'confirmtime',
          key: 'confirmtime',
          ellipsis: true,
          width: 150,
          scopedSlots: { customRender: 'confirmtime' },
        },
        {
          title: '类型',
          dataIndex: 'recordtype',
          key: 'recordtype',
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: 'recordtype' },
        },
        {
          title: '情况描述',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
          width: 240
        },
        {
          title: '处理措施',
          dataIndex: 'measure',
          key: 'measure',
          ellipsis: true,
          width: 200
        },
        {
          title: '记录人',
          dataIndex: 'recordman',
          key: 'recordman',
          ellipsis: true,
          width: 100
        },
        {
          title: '接收人',
          dataIndex: 'receiver',
          key: 'receiver',
          ellipsis: true,
          width: 100
        },
        {
          title: '是否发单',
          dataIndex: 'issendorder',
          key: 'issendorder',
          ellipsis: true,
          width: 70,
          scopedSlots: { customRender: 'issendorder' },
        },
        {
          title: '品质改进单号',
          dataIndex: 'improveordernum',
          key: 'improveordernum',
          ellipsis: true,
          width: 120
        },
        {
          title: '跟进结果',
          dataIndex: 'result',
          key: 'result',
          ellipsis: true,
          width: 150
        },
        {
          title: '完成标志',
          dataIndex: 'recordstatus',
          key: 'recordstatus',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'recordstatus' },
          width: 70
        },
        {
          title: '确认人',
          dataIndex: 'confirmman',
          key: 'confirmman',
          ellipsis: true,
          width: 70
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      isclickrow:false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '督导工作记录';
    },
    breadcrumb() {
      return [
        {
          name: '督导管理',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
      this.alarmTypes = dictionary.alarmTypes;
      this.alarmTypesMap = dictionary.alarmTypesMap;
      this.systemNames = dictionary.systemNames;
      this.systemNamesMap = dictionary.systemNamesMap;
      this.feedbackList = dictionary.feedbackList;
      this.abnormalTypes = dictionary.abnormalTypes;
      this.abnormalTypesMap = dictionary.abnormalTypesMap;
      this.equipmentTypes = dictionary.equipmentTypes;
      this.manageTypes = dictionary.manageTypes;
      this.recordTypes = dictionary.recordTypes;
    }
    this.initDeptOptionsAll();
    this.getTableData();
  },
  methods: {
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(!this.isclickrow) {
              this.modalShowType = "detail";
              this.modalDetailData = record;
              this.modalDetailData.recordstatus = Number(record.recordstatus)
              this.modalDetailData.issendorder = Number(record.issendorder)
              this.modalDetailData.recordtime = moment(record.recordtime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
              this.modalDetailData.confirmtime = record.confirmtime?moment(record.confirmtime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              this.modalVisible = true;
            }
          },
        },
      }
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
      this.queryParams.recordstatus="";
      this.userdepidCascaderSelected=[];
      this.queryInstallDate = [];
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getSuperviseListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.superviseid);
      }).catch(()=>{
      });
    },
    delete(superviseid) {
      this.showLoading();
      if(superviseid) {
        let params = {
          superviseid
        };
        deleteSupervise(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
        this.modalDetailData.recordstatus=Number(record.recordstatus)
        this.modalDetailData.issendorder=Number(record.issendorder)
        this.modalDetailData.recordtime=moment(record.recordtime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
        this.modalDetailData.confirmtime = record.confirmtime?moment(record.confirmtime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
      }
      this.modalVisible = true;
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let superviseid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'superviseid', superviseid);
      if(type == 'delete') {
        this.deleteConfirm(superviseid, record)
      } else{
        this.showModal(type, superviseid, record)
      }
    },
  }
}
</script>