<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1000" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm" :disabled="detailData&&detailData.recordstatus==3">确认</a-button>
    </template>
    <div class="simple-query">
      <a-form-model ref="queryForm" :model="queryParams" :rules="formRules" layout="inline" class="query-form">
        <a-form-model-item label="项目名称" prop="monitorpointnum" style="width: 30%">
          <a-select v-if="showType=='edit'||showType=='add'" :disabled="showType=='detail'||(detailData&&detailData.recordstatus!=1)" v-model="queryParams.monitorpointnum" placeholder="请选择" style="width: 200px;">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
          <a-input v-if="showType=='detail'"  style="width: 200px;" v-model="queryParams.monitorpointname" :disabled="showType=='detail'||showType=='edit'"></a-input>
        </a-form-model-item>
        <a-form-model-item label="记录编号" prop="recordnum" style="width: 30%">
          <a-input placeholder="系统自动生成" style="width: 200px;margin-left: 6px"v-model="queryParams.recordnum" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="记录时间" prop="recordtime" style="width: 30%">
          <a-date-picker show-time placeholder="选择时间" v-model="queryParams.recordtime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="类型" prop="recordtype" style="width: 30%">
          <a-select v-model="queryParams.recordtype" placeholder="请选择" style="width: 200px;" :disabled="showType=='detail'||(detailData&&detailData.recordstatus!=1)">
            <a-select-option v-for="(item, index) in recordTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="记录人" prop="recordman" style="width: 30%">
          <a-input v-model="queryParams.recordman" placeholder="请输入" style="width: 200px;" :disabled="showType=='detail'||(detailData&&(detailData.recordstatus==1||detailData.recordstatus==2||detailData.recordstatus==3))"></a-input>
        </a-form-model-item>
        <a-form-model-item label="上传附件" prop="superviseupload" style="width: 30%">
          <a-upload
              :disabled="showType=='detail'||(detailData&&detailData.recordstatus!=1)"
              style="width: 220px"
              name="fileList"
              :headers="uploadHeaders"
              :data="supervise"
              action="/upload"
              :file-list="fileUrlList"
              @change="fileUrlUploaded">
            <a-button :disabled="showType=='detail'||(detailData&&detailData.recordstatus!=1)"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="情况描述" prop="description" style="width: 46%">
          <a-textarea v-model="queryParams.description" placeholder="请输入" cols="48" rows="2" :disabled="showType=='detail'||(detailData&&detailData.recordstatus!=1)"></a-textarea>
        </a-form-model-item>

        <a-form-model-item label="处理措施" prop="measure" style="width: 46%">
          <a-textarea v-model="queryParams.measure" placeholder="请输入" cols="48" rows="2" :disabled="showType=='detail'||(detailData&&detailData.recordstatus!=1)"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="接收人" prop="receiver" style="width: 30%">
          <a-select v-model="queryParams.receiver" show-search :filter-option="filterOption" @change="handleChange" :disabled="true"  style="width: 200px;" :dropdownMatchSelectWidth=false>
            <a-select-option v-for="(item, index) in userList" :key="index" :value="item.userid">{{item.username}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="是否发单" prop="receiver" style="width: 30%">
          <a-select v-model.number="queryParams.issendorder" style="width: 200px;" :disabled="showType=='detail'||showType=='edit'||(detailData&&detailData.recordstatus!=1)">
            <a-select-option :value="2">是</a-select-option>
            <a-select-option :value="1">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <br/>
        <a-divider />
        <a-form-model-item label="跟进结果" prop="result" style="width: 46%">
          <a-textarea v-model="queryParams.result" placeholder="请输入" cols="48" rows="2" :disabled="showType=='detail'||(detailData&&detailData.recordstatus==3)"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="完成标志" prop="recordstatus" style="width: 20%">
          <a-select default-value="" v-model="queryParams.recordstatus" style="width: 85px" :disabled="showType=='detail'||(detailData&&detailData.recordstatus==3)" @change="calcProcessTime">
            <a-select-option :value="1" v-if="showType=='add'||detailData&&detailData.recordstatus!=2">处理中</a-select-option>
            <a-select-option :value="2" v-if="showType!='add'">待验证</a-select-option>
            <a-select-option :value="3">已完成</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.recordstatus===3" label="确认人" prop="confirmman" style="width: 25%">
          <a-input v-model="queryParams.confirmman" placeholder="请输入" style="width: 160px" :disabled="showType=='detail'||(detailData&&queryParams.recordstatus==3)"></a-input>
        </a-form-model-item>
        <a-form-model-item label="确认时间" prop="confirmtime" style="width: 30%">
          <a-date-picker show-time placeholder="选择时间" v-model="queryParams.confirmtime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
      </a-form-model>
    </div>
    <add-or-edit-modal :visible.sync="orderVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="cancelOrSubmit"></add-or-edit-modal>
  </a-modal>
</template>
<script>
import { processTypes } from "@/json/wlwhistory";
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import {addSupervise, updateSupervise} from "A/patrol";
import { getMonitorPointNameListByCondition} from "A/monitoring";
import {getUserList} from "A/xtpz";
import addOrEditModal from "V/supervise/improveOrder/addOrEditModal";
import {getCache} from "U/index";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      processTypes,
      recordTypes:[],
      modalVisible: false,
      orderVisible: false,
      modalShowType:'',
      modalDetailData:null,
      monitorpointList:[],
      userList:[],
      fileUrlList:null,
      queryParams:{
        superviseid:'',
        monitorpointnum:'',
        monitorpointname:'',
        recordnum:'',
        recordtime:'',
        recordtype:'',
        description:'',
        measure:'',
        recordman:'',
        receiver:'',
        result:'',
        improveorderid:'',
        improveordernum:'',
        confirmman:'',
        recordstatus:'',
        issendorder:'',
        superviseupload:'',
        confirmtime:null,
        verificationtime:'',
      },
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目名称'}],
        recordtime: [{required: true, message: '请选择记录时间'}],
        recordtype: [{required: true, message: '请选择记录类型'}],
        recordman: [{required: true, message: '请输入记录人'}],
        confirmman: [{required: true, message: '请输入确认人'}],
        description: [{required: true, message: '请输入情况描述'}],
        eventstatus: [{required: true, message: '请输入完成标志'}],
        measure: [{required: true, message: '请输入处理措施'}],
        recordstatus: [{required: true, message: '请选择完成标志'}],
      },
      formData:{
        monitorpointnum:'',
        improveordernum:'',
        orderstime:'',
        receiver:'',
        description:'',
        responsible:'',
        analysistime:'',
        causeanalysis:'',
        approver:'',
        improvetime:'',
        scheduledtime:'',
        improvemeasures:'',
        verifier:'',
        verificationtime:'',
        verification:'',
        superviseupload:'',
        improveupload:'',
      },
      supervise:{
        folder: 'superviseFile',
        time: moment(new Date()).format("YYYYMMDDHHmmss"),
      },
    }
  },
  computed: {
    ...mapGetters(['uploadHeaders', 'uploadSupervise']),
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '跟进'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    "queryParams.issendorder":function (){
      if(this.showType=="add"&&this.queryParams.issendorder==2){
        getUserList({usertype:1,rolename:"项目管理员",monitorpointnum:this.queryParams.monitorpointnum}).then(res=>{
          if(res&&res.returncode=='0'){
            this.userList=res.item
            if(res.item){
              if(this.queryParams.issendorder==2){
                this.queryParams.receiver=res.item[0].userid
                this.formData.monitorpointnum=this.queryParams.monitorpointnum
                this.formData.receiver=this.queryParams.receiver
                this.formData.description=this.queryParams.description
                this.formData.orderstime=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                this.formData.superviseupload=this.queryParams.superviseupload
                this.modalShowType='add'
                this.modalDetailData=this.formData
                this.orderVisible=true
              }else{
                this.queryParams.issendorder = 1
                this.queryParams.receiver = ""
                this.queryParams.improveordernum = ""
              }
            }
          }
        })
      }else{
        this.orderVisible=false
        this.queryParams.receiver = ""
        this.queryParams.improveordernum = ""
      }
    },
    "queryParams.monitorpointnum":function (){
      getUserList({usertype:1,rolename:"项目管理员",monitorpointnum:this.queryParams.monitorpointnum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.userList=res.item
          if(res.item){
            if(this.queryParams.issendorder==2){
              this.queryParams.receiver=res.item[0].userid
            }else{
              this.queryParams.receiver = ""
              this.queryParams.improveordernum = ""
            }
          }
        }
      })
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    handleChange(value) {
      this.queryParams.improveorderid=value
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    initDetail() {
      let dictionary = getCache('dictionary', true);
      if(dictionary) {
        this.bigInfluences = dictionary.bigInfluences;
        this.alarmTypes = dictionary.alarmTypes;
        this.alarmTypesMap = dictionary.alarmTypesMap;
        this.systemNames = dictionary.systemNames;
        this.systemNamesMap = dictionary.systemNamesMap;
        this.feedbackList = dictionary.feedbackList;
        this.abnormalTypes = dictionary.abnormalTypes;
        this.abnormalTypesMap = dictionary.abnormalTypesMap;
        this.equipmentTypes = dictionary.equipmentTypes;
        this.manageTypes = dictionary.manageTypes;
        this.recordTypes = dictionary.recordTypes;
      }
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.queryParams) {
            if(key=="recordtype"){
              this.queryParams[key] = this.detailData[key].toString() || '';
            }else if(key=="confirmtime"){
              if(this.detailData[key]=="Invalid date"||!this.detailData[key]){
                this.queryParams[key]=null
              }else{
                this.queryParams[key] = this.detailData[key].toString() || '';
              }
            }else if(key=="confirmman"){
              if(this.detailData['recordstatus']==3){
                this.queryParams[key] = this.detailData[key].toString() || '';
              }else{
                this.queryParams.confirmman = this.userInfo.username
              }
            }else{
              this.queryParams[key] = this.detailData[key] || '';
            }
          }
          if (this.detailData.superviseupload) {
            let nameIndex = this.detailData.superviseupload.lastIndexOf('/');
            if (nameIndex < 0) {
              nameIndex = this.detailData.superviseupload.lastIndexOf('\\');
            }
            let fileName = this.detailData.superviseupload.substr(nameIndex + 1);
            this.fileUrlList = [{
              uid: 'fileuid',
              name: fileName,
              status: 'done',
              url: this.detailData.superviseupload,
            }];
          }
        })
      }else{
        // this.queryParams.recordman = this.userInfo.username
        // this.queryParams.confirmman = this.userInfo.username
        this.queryParams.issendorder=1
        this.queryParams.receiver = ""
        this.queryParams.improveordernum = ""
        this.queryParams.recordtime=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      }
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetDatas() {
      this.$refs.queryForm.resetFields();
      this.fileUrlList=[]
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.queryForm.validate(valid => {
          if (valid) {
            this.showLoading();
            this.queryParams.recordtime = moment(this.queryParams.recordtime).format("YYYYMMDDHHmmss")
            if(this.queryParams.confirmtime){
              this.queryParams.confirmtime = moment(this.queryParams.confirmtime).format("YYYYMMDDHHmmss")
            }
            if(this.queryParams.issendorder===1){
              this.queryParams.receiver = ""
              this.queryParams.improveordernum = ""
            }
            let params = {
              ...this.queryParams
            };
            if (this.showType == 'add') {
              addSupervise(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.queryParams.improveorderid=''
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              updateSupervise(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    },
    cancelOrSubmit(e) {
      if (e == "cancel") {
        this.orderVisible = false
        this.queryParams.issendorder = 1
      } else {
        this.queryParams.improveorderid=e
        this.orderVisible = false
        this.queryParams.issendorder = 2
      }
    },
    fileUrlUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.name=this.supervise.time+"-"+file.name;
          file.response.urlPath="file/superviseFile/"+file.name
          file.url = file.response.urlPath;
          this.queryParams.superviseupload = file.response.urlPath;
        }
        return file;
      });
      this.fileUrlList = fileList;
    },
    calcProcessTime(){
      if(this.queryParams.recordstatus==3){
        this.queryParams.confirmtime=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        let recordtime=Date.parse(new Date(moment(this.queryParams.recordtime).format("YYYY-MM-DD HH:mm:ss")))/1000
        let verificationtime=this.queryParams.verificationtime?Date.parse(new Date(moment(this.queryParams.verificationtime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")))/1000:0
        let confirmtime=Date.parse(new Date(moment(this.queryParams.confirmtime).format("YYYY-MM-DD HH:mm:ss")))/1000
        if(this.queryParams.issendorder==1&&((confirmtime-recordtime)/60).toFixed(2)<0){
          this.queryParams.recordstatus=''
          this.queryParams.confirmtime=null
          this.$message.error("督导记录确认时间早于记录时间，请复核")
        }else if(this.queryParams.issendorder==2){
          if(verificationtime==0){
            this.queryParams.recordstatus=''
            this.queryParams.confirmtime=null
            this.$message.error("督导记录“确认时间”或者品质改进单“验收时间”为空，请复核")
          }else if(((confirmtime-verificationtime)/60).toFixed(2)<0){
            this.queryParams.recordstatus=''
            this.queryParams.confirmtime=null
            this.$message.error("督导记录“确认时间”早于品质改进单“验证时间”，请复核")
          }
        }
      }
    }
  }
}
</script>